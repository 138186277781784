/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
	function add_maps_script_tag() {
		var maps_api_key = 'AIzaSyB4ggmJ-ad0sQLg4sUdKheQo5tS6v9l3NE';
		var maps_callback = 'document.initMap';
		var maps_api_url = 'https://maps.google.com/maps/api/js';
		var maps_script_tag = document.createElement('script');
		maps_script_tag.setAttribute('type', 'text/javascript');
		maps_script_tag.setAttribute('src', maps_api_url + '?key=' + maps_api_key + '&callback=' + maps_callback);
		maps_script_tag.setAttribute('defer', '');
		maps_script_tag.setAttribute('async', '');
		document.getElementsByTagName('body')[0].appendChild(maps_script_tag);
	}

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
				// toggles hamburger and nav open and closed states
				var removeClass = true;
				$(".hamburger").click(function() {
					$(".hamburger").toggleClass('is-active');
					$("#sideNav").toggleClass('sideNav-open');
					$(".sideNavBody").toggleClass('sideNavBody-push');
					removeClass = false;
				});

				$(".sideNav").click(function() {
					removeClass = false;
				});

				if (!Cookies.get('alert') && $('#modal').length) {
					$('#modal').show();
				}
				
				$('.modal .btn-close').click(function() {
					$('#modal').hide();
					Cookies.set('alert', true, {
						expires: 2,
					});
				});
				document.addEventListener('touchstart', function(e) {
					if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
						$(".hamburger").removeClass('is-active');
						$("#sideNav").removeClass('sideNav-open');
						$(".sideNavBody").removeClass('sideNavBody-push');
					}
					removeClass = true;
				}, false);
				$(window).scroll(function() {
					if ($(this).scrollTop() > 100) {
						$('.scrollup').fadeIn();
					} else {
						$('.scrollup').fadeOut();
					}
				});

				$('.scrollup').click(function() {
					$("html, body").animate({
						scrollTop: 0
					}, 600);
					return false;
				});

        $('#sideNav .menu-item-has-children > a').click(function(e) {
          e.preventDefault();
          $(this).parent().toggleClass('dropdown-toggle-open');
        });

				$('.owners-gallery').lightGallery({
					selector: '.owners-gallery .gallery-image',
				});
			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
			},
			finalize: function() {
				// JavaScript to be fired on the home page, after the init JS

				$('.slider-for').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					fade: true,
					asNavFor: '.slider-nav'
				});

				$('.slider-nav').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					asNavFor: '.slider-for',
					dots: true
				});

				$('.slider-for-c').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					fade: true,
					asNavFor: '.slider-nav-c'
				});

				$('.slider-nav-c').slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					asNavFor: '.slider-for-c',
					dots: true
				});

				$('.slick-testimonials').slick({
					infinite: true,
					slidesToShow: 2,
					slidesToScroll: 1,
					dots: true,
					responsive: [{
						breakpoint: 768,
						settings: {
							slidesToShow: 1
						}
					}]
				});

			}
		},
		'has_filters': {
			init: function() {},
			finalize: function() {

				// change
				$('#search-filter-button').click(function(e) {
					e.preventDefault();

					// vars
					var url = window.location.pathname;
          if (url.indexOf('page/') >= 0) {
            url = url.substring(0, url.indexOf('page/'));
          }

					args = {};
					// loop over filters
					$('#archive-filters .filter').each(function() {
						// vars
						var filter = $(this).data('filter'),
							vals = [];

						// if statement finds checked inputs, as well as inputs that have class .minmax
						$(this).find('input').each(function() {
							if ($(this).prop('checked') || $(this).hasClass('minmax')) {
								vals.push($(this).val());
							}
						});
						//added this function in order to find option:selected as well.
						$(this).find('option:selected').each(function() {
							vals.push($(this).val());
						});

						// append to args
						args[filter] = vals.join(',');
					});
					// update url
					url += '?';
					// loop over args
					$.each(args, function(name, value) {
						if (value !== '') {
							url += name + '=' + value + '&';
						}
					});
					// remove last &
					url = url.slice(0, -1);
					// reload page
					window.location.replace(url);
				});

				$('#clear-filter-button').click(function(e) {
					e.preventDefault();
					var url = window.location.pathname;

          if (url.indexOf('page/') >= 0) {
            url = url.substring(0, url.indexOf('page/'));
          }

					window.location.replace(url);
				});

			}
		},

		'single_property_listing': {
			init: function() {
				// JavaScript to be fired on the Listing page

				$('#imageGalleryOne').lightSlider({
					gallery: true,
					item: 1,
					loop: true,
					thumbItem: 5,
					slideMargin: 0,
					enableDrag: false,
					verticalHeight: 600,
					controls: false,
					currentPagerPosition: 'left',
					responsive: [{
							breakpoint: 992,
							settings: {
								verticalHeight: 350,
								thumbItem: 5
							}
						},
						{
							breakpoint: 576,
							settings: {
								verticalHeight: 350,
								thumbItem: 3
							}
						}
          ],
          onBeforeSlide: function() {
            $('.slider-video iframe').attr('src', $('.slider-video iframe').attr('src'));
          },
          
					onSliderLoad: function(el) {
						el.lightGallery({
							selector: '#imageGalleryOne .lslide'
						});
					}
				});

				$('#myModal').on('shown.bs.modal', function() {
					$('#myInput').focus();
        });

			},
			finalize: function() {

				document.initMap = function() {
					function add_marker($marker, map) {
						var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
						var marker = new google.maps.Marker({
							position: latlng,
							map: map
						});
						map.markers.push(marker);
						if ($marker.html()) {
							var infowindow = new google.maps.InfoWindow({
								content: $marker.html()
							});
							google.maps.event.addListener(marker, 'click', function() {
								infowindow.open(map, marker);
							});
						}
					}

					function center_map(map) {
						var bounds = new google.maps.LatLngBounds();
						$.each(map.markers, function(i, marker) {
							var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
							bounds.extend(latlng);
						});
						if (map.markers.length === 1) {
							map.setCenter(bounds.getCenter());
							map.setZoom(16);
						} else {
							map.fitBounds(bounds);
						}
					}

          			function new_map($el) {
						var $markers = $el.find('.marker');
						var args = {
							zoom: 16,
							center: new google.maps.LatLng(0, 0),
							mapTypeId: google.maps.MapTypeId.ROADMAP
						};
						var map = new google.maps.Map($el[0], args);
						map.markers = [];
						$markers.each(function() {
							add_marker($(this), map);
						});
						center_map(map);
						return map;
					}

					var map = null;
					$(document).ready(function() {
						$('.acf-map').each(function() {
							map = new_map($(this));
						});
					});
				};

				add_maps_script_tag();
			}
		},
		'page_template_template_tt_listing': {
			init: function() {
				// JavaScript to be fired on the Listing page
				$('#imageGalleryOne').lightSlider({
					gallery: true,
					item: 1,
					loop: true,
					thumbItem: 5,
					slideMargin: 0,
					enableDrag: false,
					verticalHeight: 600,
					controls: false,
					currentPagerPosition: 'left',
					responsive: [{
							breakpoint: 992,
							settings: {
								verticalHeight: 350,
								thumbItem: 5
							}
						},
						{
							breakpoint: 576,
							settings: {
								verticalHeight: 350,
								thumbItem: 3
							}
						}
					],
					onSliderLoad: function(el) {
						el.lightGallery({
							selector: '#imageGalleryOne .lslide:not(.slider-video)'
						});
					}
				});

			},
			finalize: function() {

				document.initMap = function() {
					function add_marker($marker, map) {
						var address = $marker.attr('data-addr');
						var state = $marker.attr('data-state');
						var geocoder = new google.maps.Geocoder();
						console.log(address);
						geocoder.geocode( { 'address': address}, function(results, status) {
							if (status === 'OK') {
								console.log(results);
								var loc = results[0].geometry.location;
								$.each( results, function( i, result ){
									if( typeof state !== 'undefined' && result.formatted_address.indexOf(state) >= 0 ){
										loc = result.geometry.location;
									}
								});
								map.setCenter(loc);
								var marker = new google.maps.Marker({
									map: map,
									position: loc
								});
							} else {
							  alert('Geocode was not successful for the following reason: ' + status);
							}
						});
					}

					function center_map(map) {
						var bounds = new google.maps.LatLngBounds();
						$.each(map.markers, function(i, marker) {
							var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
							bounds.extend(latlng);
						});
						if (map.markers.length === 1) {
							map.setCenter(bounds.getCenter());
							map.setZoom(16);
						} else {
							map.fitBounds(bounds);
						}
					}

          			function new_map($el) {
						var $markers = $el.find('.marker');
						var args = {
							zoom: 16,
							center: new google.maps.LatLng(0, 0),
							mapTypeId: google.maps.MapTypeId.ROADMAP
						};
						var map = new google.maps.Map($el[0], args);
						map.markers = [];
						$markers.each(function() {
							add_marker($(this), map);
						});
						return map;
					}

					var map = null;
					$(document).ready(function() {
						$('.acf-map').each(function() {
							map = new_map($(this));
						});
					});
				};

        add_maps_script_tag();
        
        $('.imageGallery li.slider-video').on('click', function (e) {
          var $this = $(this);
          e.preventDefault();
          $this.addClass('hasIframe').append('<iframe width="100%" height="315" src="' + $this.attr('data-iframe') + '" frameborder="0" allowfullscreen></iframe>');
          $this.removeClass('slider-video-overlay');
          console.log($this);
        });
			}
		},
		'contact': {
			finalize: function() {
/*
				document.initMap = function() {
			        function add_marker($marker, map) {
			          var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
			          var marker = new google.maps.Marker({
			            position: latlng,
			            map: map
			          });
			          map.markers.push(marker);
			          if ($marker.html()) {
			            var infowindow = new google.maps.InfoWindow({
			              content: $marker.html()
			            });
			            google.maps.event.addListener(marker, 'click', function() {
			              infowindow.open(map, marker);
			            });
			          }
			        }

			        function center_map(map) {
			          var bounds = new google.maps.LatLngBounds();
			          $.each(map.markers, function(i, marker) {
			            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
			            bounds.extend(latlng);
			          });
			          if (map.markers.length === 1) {
			            map.setCenter(bounds.getCenter());
			            map.setZoom(16);
			          } else {
			            map.fitBounds(bounds);
			          }
			        }

			        function new_map($el) {
			          var $markers = $el.find('.marker');
			          var args = {
			            zoom: 16,
			            center: new google.maps.LatLng(0, 0),
			            mapTypeId: google.maps.MapTypeId.ROADMAP
			          };
			          var map = new google.maps.Map($el[0], args);
			          map.markers = [];
			          $markers.each(function() {
			            add_marker($(this), map);
			          });
			          center_map(map);
			          return map;
			        }

			        var map = null;
			        $(document).ready(function() {
			          $('.acf-map').each(function() {
			            map = new_map($(this));
			          });
			        });
				};

				add_maps_script_tag();
*/				
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
